import { Component, Vue } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { getKpiClass } from '@/support/Dashboard';
import { StatisticsNumberWidgetProps } from '@/components/dashboard/StatisticsNumberWidget/StatisticsNumberWidget';
import { Label } from '@/components/List/List';
import { Rpc } from '@/models/Rpc';
import ReportLevelFilter from '@/components/filters/report-level-filter/ReportLevelFilter.vue';

@Component<ExpertRegisterDashboard>({
  components: {
    ReportLevelFilter,
  },
})
export default class ExpertRegisterDashboard extends Vue {
  // #region @Props
  // #endregion

  // #region @Refs
  // #endregion

  // #region Optional @PropSyncs
  // #endregion

  // #region Class properties

  protected isLoadingStatistics = false;

  protected statisticsFilters: StatisticsFilters = {};

  protected statistics: null | Statistics = null;
  // #endregion

  // #region Plugin properties

  public $pageTitle = 'Deskundigenregister Dashboard';

  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted(): void {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.getStatistics();
  }

  // #endregion

  // #region Class methods: Handlers

  protected statisticsFilterChanged(): void {
    // eslint-disable-next-line no-restricted-syntax
    for (const propName in this.statisticsFilters) {
      if (! (this.statisticsFilters as any)[propName] || ! (this.statisticsFilters as any)[propName].length) {
        delete (this.statisticsFilters as any)[propName];
      }
    }

    this.getStatistics();
  }

  // #endregion

  // #region Class methods: Helpers

  protected emitBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Deskundigenregister Dashboard' }],
    });
  }

  protected getKpiClass(amount: number, warningAt = 5, alertAt = 10, inverted = false): string {
    return getKpiClass(amount, warningAt, alertAt, inverted);
  }

  // #endregion

  // #region Async methods

  protected async getStatistics(): Promise<void> {
    this.isLoadingStatistics = true;

    if (! this.$store.state.isServiceOrganization) {
      this.statisticsFilters.organizations = [this.$store.state.Auth?.organization?.id];
    }

    const statistics: null | Statistics = await new Rpc()
      .rpcPost({
        signature: 'expert-register:dashboard',
        body: {
          filters: this.statisticsFilters,
        },
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (statistics) {
      this.statistics = statistics;
    }

    this.isLoadingStatistics = false;
  }

  // #endregion

  // #region Getters & Setters

  protected get statisticsNumberWidgetAantalActieveDeskundigenProps(): StatisticsNumberWidgetProps {
    return {
      item: {
        amount: `${this.statistics?.user_data?.users}`,
        // class: [this.getKpiClass(1234, 93, 90, true)], ^MO Delete after 2 months
        description: 'Aantal actieve deskundigen',
      },
    };
  }

  protected get statisticsNumberWidgetFteProps(): StatisticsNumberWidgetProps {
    return {
      item: {
        amount: `${this.statistics?.user_data?.fte}`,
        // class: [this.getKpiClass(1234, 93, 90, true)], ^MO Delete after 2 months
        description: 'FTE',
      },
    };
  }

  protected get statisticsNumberWidgetOpenstaandeAanvragenProps(): StatisticsNumberWidgetProps {
    return {
      item: {
        amount: `${this.statistics?.user_data?.open_requests}`,
        // class: [this.getKpiClass(1234, 93, 90, true)], ^MO Delete after 2 months
        description: 'Openstaande aanvragen',
      },
    };
  }

  protected get competentieListProps(): CompetentieListProps {
    return {
      items: this.statistics?.skill_data
        ? Object.keys(this.statistics.skill_data).map((skill) => ({
          skill,
          amount: this.statistics?.skill_data?.[skill],
        }))
        : [],
      labels: [
        {
          slotName: 'skill',
          title: 'Competenties',
          width: 'xs6',
        },
        {
          slotName: 'amount',
          title: '',
          width: 'xs6',
        },
      ],
    };
  }

  // #endregion

  // #region @Watchers
  // #endregion
}

// #region Enums
// #endregion

// #region Types
// #endregion

// #region Interfaces

interface StatisticsFilters {
  levels?: string[];
  organizations?: string[];
}

interface StatisticsUserData {
  users?: number;
  fte?: string;
  open_requests?: number;
}

type StatisticsSkillData = Record<string, number>;

interface Statistics {
  user_data?: StatisticsUserData;
  skill_data?: StatisticsSkillData;
}

interface CompetentieListItem {
  amount?: number;
  skill?: string;
}

interface CompetentieListProps {
  items?: CompetentieListItem[];
  labels?: Label[];
}

// #endregion
