var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Dashboard Deskundigenregister")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--user" },
              [
                _c(
                  "v-layout",
                  { attrs: { column: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-flex tw-flex-row tw-pb-32 tw-gap-16"
                          },
                          [
                            _vm.$store.state.isServiceOrganization
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-filter custom-filter--autocomplete"
                                  },
                                  [
                                    _c("ReportOrganizationFilter", {
                                      attrs: {
                                        placeholder: "Organisatie",
                                        canSelectAll: true
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.statisticsFilterChanged()
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.statisticsFilters.organizations,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.statisticsFilters,
                                            "organizations",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "statisticsFilters.organizations"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "custom-filter custom-filter--autocomplete"
                              },
                              [
                                _c("ReportLevelFilter", {
                                  attrs: {
                                    placeholder: "Niveau",
                                    canSelectAll: true
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.statisticsFilterChanged()
                                    }
                                  },
                                  model: {
                                    value: _vm.statisticsFilters.levels,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.statisticsFilters,
                                        "levels",
                                        $$v
                                      )
                                    },
                                    expression: "statisticsFilters.levels"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm.isLoadingStatistics
                          ? _c("LoaderCard", {
                              attrs: {
                                flat: "",
                                minHeight: "300px",
                                type: "spinner--center"
                              }
                            })
                          : !_vm.statistics
                          ? _c("div", [
                              _c("p", [_vm._v("Geen statistieken gevonden")])
                            ])
                          : [
                              _c(
                                "div",
                                { staticClass: "tw-flex tw-flex-row tw-pb-32" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-flex tw-w-1/3 tw-text-center tw-justify-around"
                                    },
                                    [
                                      _c(
                                        "StatisticsNumberWidget",
                                        _vm._b(
                                          {},
                                          "StatisticsNumberWidget",
                                          _vm.statisticsNumberWidgetAantalActieveDeskundigenProps,
                                          false
                                        )
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-flex tw-w-1/3 tw-text-center tw-justify-around"
                                    },
                                    [
                                      _c(
                                        "StatisticsNumberWidget",
                                        _vm._b(
                                          {},
                                          "StatisticsNumberWidget",
                                          _vm.statisticsNumberWidgetFteProps,
                                          false
                                        )
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-flex tw-w-1/3 tw-text-center tw-justify-around"
                                    },
                                    [
                                      _c(
                                        "StatisticsNumberWidget",
                                        _vm._b(
                                          {},
                                          "StatisticsNumberWidget",
                                          _vm.statisticsNumberWidgetOpenstaandeAanvragenProps,
                                          false
                                        )
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "tw-flex tw-flex-row tw-pb-32" },
                                [
                                  !_vm.isLoadingStatistics
                                    ? _c(
                                        "List",
                                        _vm._b(
                                          {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "skill",
                                                  fn: function(item) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.skill) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "amount",
                                                  fn: function(item) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.amount) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              30264009
                                            )
                                          },
                                          "List",
                                          _vm.competentieListProps,
                                          false
                                        )
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }